import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "SetKeyConfig"
};
import { ref } from "vue";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
export default {
  __name: 'KeyMenu-SetKeyConfig',
  emits: ['ChangeKeyConfig'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const columns = [[{
      text: '[类型]注册码',
      value: 'reg',
      field: 'type',
      int: 1
    }, {
      text: '[类型]充值码',
      value: 'rec',
      field: 'type',
      int: 2
    }, {
      text: '[类型]全部',
      value: 'allkey',
      field: 'type',
      int: 3
    }], [{
      text: '[状态]未使用',
      value: 'nouse',
      field: 'status',
      int: 0
    }, {
      text: '[状态]已使用',
      value: 'use',
      field: 'status',
      int: 1
    }, {
      text: '[状态]全部',
      value: 'allstatus',
      field: 'status',
      int: 3
    }]];
    const showPicker = ref(false);
    const OpenSetKeyConfigView = () => {
      showPicker.value = true;
    };
    expose({
      OpenSetKeyConfigView
    });
    const onConfirm = ({
      selectedValues,
      selectedOptions,
      selectedIndexes
    }) => {
      console.log('onConfirm', selectedValues, selectedOptions, selectedIndexes);
      showPicker.value = false;
      if (!Array.isArray(selectedOptions) || selectedOptions.length !== 2) {
        VantMsgLib.alertError('设置模式异常');
        return;
      }
      let msg = {};
      let obj = null;
      obj = GetOptionsData(selectedOptions[0]);
      msg[obj.field] = obj.int;
      obj = GetOptionsData(selectedOptions[1]);
      msg[obj.field] = obj.int;
      console.log('msg', msg);
      emits('ChangeKeyConfig', msg);
      VantMsgLib.alertSuccess('已切换 ' + selectedOptions[0].text + ' / ' + selectedOptions[1].text);
    };
    const GetOptionsData = arr => {
      let field = arr.field;
      let int = arr.int;
      return {
        field,
        int
      };
    };
    return (_ctx, _cache) => {
      const _component_van_picker = _resolveComponent("van-picker");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: showPicker.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => showPicker.value = $event),
        round: "",
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          columns: columns,
          onCancel: _cache[0] || (_cache[0] = $event => showPicker.value = false),
          onConfirm: onConfirm
        })]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};