import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, toRaw } from "vue";
import Common_Str from "@/utils/Common/Common_Str";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'PickTime',
  emits: ['ChangeTime'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const LangLib = new LangCom('Time', 'PickTime');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const NowDate = new Date();
    let show = ref(false);
    const OpenPickTimeView = () => {
      show.value = true;
    };
    expose({
      OpenPickTimeView
    });
    const DateObj = ref({
      startDate: ['2022', '06', '01'],
      endDate: [NowDate.getFullYear().toString(), Common_Str.fixZeroStart(NowDate.getMonth() + 1, 2), Common_Str.fixZeroStart(NowDate.getDate(), 2)],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 1, 1)
    });
    const onConfirm = () => {
      let obj = toRaw(DateObj.value);
      let {
        startDate,
        endDate
      } = obj;
      let [startInt, endInt] = [ArrToInt(startDate), ArrToInt(endDate)];
      if (startInt > endInt) {
        VantMsgLib.alertError(GetLangSetup('onConfirm.tips'));
        return;
      }
      let msg = {
        start: {
          int: startInt,
          arr: startDate
        },
        end: {
          int: endInt,
          arr: endDate
        }
      };
      emits('ChangeTime', msg);
      show.value = false;
    };
    const ArrToInt = arr => {
      let str = arr.join('');
      return str;
    };
    const onCancel = () => {
      show.value = false;
    };
    return (_ctx, _cache) => {
      const _component_van_date_picker = _resolveComponent("van-date-picker");
      const _component_van_picker_group = _resolveComponent("van-picker-group");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: _unref(show),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(show) ? show.value = $event : show = $event),
        position: "center",
        style: {
          width: '100%'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker_group, {
          title: GetLangTemplate('group.title'),
          tabs: [GetLangTemplate('group.tabs0'), GetLangTemplate('group.tabs1')],
          onConfirm: onConfirm,
          onCancel: onCancel
        }, {
          default: _withCtx(() => [_createVNode(_component_van_date_picker, {
            modelValue: DateObj.value.startDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => DateObj.value.startDate = $event),
            "min-date": DateObj.value.minDate,
            "max-date": DateObj.value.maxDate
          }, null, 8, ["modelValue", "min-date", "max-date"]), _createVNode(_component_van_date_picker, {
            modelValue: DateObj.value.endDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => DateObj.value.endDate = $event),
            "min-date": DateObj.value.minDate,
            "max-date": DateObj.value.maxDate
          }, null, 8, ["modelValue", "min-date", "max-date"])]),
          _: 1
        }, 8, ["title", "tabs"])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};