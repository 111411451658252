/**
 * 对象方面的公用函数
 */
const Common_Obj = {
    /**
     * 检查两个对象是否[相同/相等]
     * @param obj1
     * @param obj2
     * @return {boolean}
     * 相等返回true
     */
    isEqual(obj1,obj2){
        let bool = true;
        for (let obj1Key in obj1) {
            let temp = obj1[obj1Key];
            bool = temp == obj2[obj1Key]
            if (!bool){
                break;
            }
        }
        return bool;
    },

    /**
     * 深度拷贝对象
     * 避免原对象受响应式影响[解决指向同一个内存地址的问题]
     * @param target
     * @return {any}
     */
    DeepClone(target) {
        // 定义一个变量
        let result;
        // 如果当前需要深拷贝的是一个对象的话
        if (typeof target === 'object') {
            // 如果是一个数组的话
            if (Array.isArray(target)) {
                result = []; // 将result赋值为一个数组，并且执行遍历
                for (let i in target) {
                    // 递归克隆数组中的每一项
                    result.push(this.DeepClone(target[i]))
                }
                // 判断如果当前的值是null的话；直接赋值为null
            } else if(target===null) {
                result = null;
                // 判断如果当前的值是一个RegExp对象的话，直接赋值
            } else if(target.constructor===RegExp){
                result = target;
            }else {
                // 否则是普通对象，直接for in循环，递归赋值对象的所有值
                result = {};
                for (let i in target) {
                    result[i] = this.DeepClone(target[i]);
                }
            }
            // 如果不是对象的话，就是基本数据类型，那么直接赋值
        } else {
            result = target;
        }
        // 返回最终结果
        return result;
    }
};
export default Common_Obj