import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c34d9892"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "TableNetWorkMain"
};
import NetWorkTable from './Table/TableNetWork-Table';
import { toRaw, ref } from "vue";
import TableNetWorkHeader from "@/components/Table_NetWork/Table/TableNetWork-Header";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Common_Router from "@/utils/Common/Common_Router";
import TableNetWorkPage from "@/components/Table_NetWork/Table/TableNetWork-Page";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'TableNetWork',
  props: ['Options'],
  emits: ['TableEvent'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const LangLib = new LangCom('TableNetWork', 'TableNetWorkIndex');
    //const GetLangTemplate = field => LangLib.GetTemplateLang(field)
    const GetLangSetup = field => LangLib.GetSetupLang(field);

    /**
     * props.Options参数{count,page,size,tableData,headerTitle,checkbox}
     * tableData解析:{
     *     title:标题
     *     label:附带字符串
     *     data:附带数据
     * }
     */

    //[头部组件]执行动作
    const refsTable = ref();
    const HeaderOnSelect = msg => {
      switch (msg.type) {
        case 1:
          //全选
          refsTable.value.checkboxGroup.toggleAll(true);
          break;
        case 2:
          //取消全选
          refsTable.value.checkboxGroup.toggleAll(false);
          break;
        case 3:
          //加入待操作的用户列表
          if (refsTable.value.checked.length) {
            SendTableEvent(2, toRaw(refsTable.value.checked));
          } else {
            VantMsgLib.notify(4, GetLangSetup('HeaderOnSelect.tips'));
          }
          break;
        case 4:
          //刷新页面
          Common_Router.RefreshPage();
          break;
        case 5:
          //呼出工具栏
          SendTableEvent(1);
          break;
        case 6:
          //点击了放大镜图标[筛选/搜索]
          SendTableEvent(5);
      }
    };

    /**
     * 向父组件传递事件
     * @param type [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项 4.页数变动 5.点击了放大镜图标[筛选/搜索]
     * @param data 数据
     * @param attach 附带消息
     * @constructor
     */
    const SendTableEvent = (type, data = null, attach = null) => {
      let res = {
        type,
        data,
        attach
      };
      emits('TableEvent', res);
    };
    const PageEvent = options => {
      SendTableEvent(4, options.page);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TableNetWorkHeader), {
        HeaderTitle: props.Options.headerTitle,
        CheckBox: props.Options.checkbox,
        onHeaderOnSelect: HeaderOnSelect
      }, null, 8, ["HeaderTitle", "CheckBox"]), _createVNode(_unref(NetWorkTable), {
        ref_key: "refsTable",
        ref: refsTable,
        TableData: props.Options.talbeData,
        CheckBox: props.Options.checkbox,
        onClickTable: _cache[0] || (_cache[0] = options => SendTableEvent(3, options.item, options.index))
      }, null, 8, ["TableData", "CheckBox"]), _createVNode(_unref(TableNetWorkPage), {
        Total: props.Options.count,
        Size: props.Options.size,
        Page: props.Options.page,
        onPageEvent: PageEvent
      }, null, 8, ["Total", "Size", "Page"])]);
    };
  }
};