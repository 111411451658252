import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "View"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1,
  style: {
    "margin-top": "20px"
  }
};
import { ref, computed } from "vue";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'Table-SetList',
  props: ['field'],
  setup(__props, {
    expose
  }) {
    const props = __props;
    const LangLib = new LangCom('Table', 'TableSetList');
    // const GetLangTemplate = field => LangLib.GetTemplateLang(field)
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    let show = ref(false);
    let UserArr = ref([]);
    /**
     * 打开本组件
     * @constructor
     */
    const OpenView = () => {
      show.value = true;
      UserArr.value = GetCacheUserList();
    };
    /**
     * 从缓存获取管理列表数组
     * @return {*[]|*}
     * @constructor
     */
    const GetCacheUserList = () => {
      let cache = CacheData.GetData(props.field);
      if (!cache || !Array.isArray(cache)) {
        return [];
      }
      if (cache.length <= 0) {
        VantMsgLib.notify(4, GetLangSetup('GetCacheUserList.length'));
      }
      return cache;
    };
    /**
     * [计算属性]返回当前管理列表数组的长度
     * @type {ComputedRef<number>}
     */
    const getUserArrLength = computed(() => {
      return UserArr.value.length;
    });
    expose({
      OpenView
    });
    const ClickUser = item => {
      console.log('ClickUser', item);
      VantMsgLib.confirm(GetLangSetup('ClickUser.title'), GetLangSetup('ClickUser.message') + `[${item.username}]?`, () => {
        let arr = GetCacheUserList();
        let res = arr.filter(temp => temp.id !== item.id);
        if (res.length !== arr.length) {
          CacheData.SaveData(props.field, res);
          UserArr.value = GetCacheUserList();
        } else {
          VantMsgLib.notify(4, GetLangSetup('ClickUser.error'));
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        round: "",
        show: _unref(show),
        "onUpdate:show": _cache[0] || (_cache[0] = $event => _isRef(show) ? show.value = $event : show = $event),
        closeable: "",
        "close-icon": "close",
        position: "bottom",
        style: {
          height: '40vh'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(getUserArrLength) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_van_list, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(UserArr), (item, index) => {
            return _openBlock(), _createBlock(_component_van_cell, {
              key: item.id,
              title: index + 1 + '.' + ' ' + item.username,
              onClick: $event => ClickUser(item)
            }, null, 8, ["title", "onClick"]);
          }), 128))]),
          _: 1
        })])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_van_empty, {
          description: "No Data"
        })]))])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};