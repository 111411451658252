import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "KeyListFilterView"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  style: {
    "margin": "16px"
  }
};
const _hoisted_4 = {
  style: {
    "margin": "16px"
  }
};
import { ref } from "vue";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import PickTime from "@/components/Time/PickTime";
import Apis_Key from "@/apis/Apis_Key";
export default {
  __name: 'KeyList-Filter',
  props: ['Config'],
  emits: ['FilterEvent'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;
    let show = ref(false);
    let FilterOptions = ref([{
      name: '卡密',
      field: 'key',
      val: '',
      type: 1
    }, {
      name: '使用者',
      field: 'use',
      val: '',
      type: 2
    }, {
      name: '条形码',
      field: 'id',
      val: '',
      type: 3
    }, {
      name: '时间',
      field: 'time',
      val: '',
      type: 4
    }, {
      name: '备注',
      field: 'nickname',
      val: '',
      type: 5
    }]);
    const OpenFilterView = () => {
      show.value = true;
      let arr = FilterOptions.value;
      for (let arrKey in arr) {
        FilterOptions.value[arrKey].val = '';
      }
    };
    expose({
      OpenFilterView
    });
    const onSubmit = () => {
      let form = FilterOptions.value;
      let res = GetFilter(form);
      if (!res) {
        VantMsgLib.alertError('请设置筛选条件');
        return;
      }
      let postData = {
        type: props.Config.type,
        ...res,
        agent: props.Config.agent
      };
      Apis_Key.filterKey(postData).then(res => {
        if (!Apis_Key.checkResCode(res)) {
          return;
        }
        let arr = res.data || [];
        let length = arr.length;
        VantMsgLib.notify(2, '筛选卡密完成,数量:' + length);
        emits('FilterEvent', arr);
        show.value = false;
      });
    };
    const GetFilter = arr => {
      let res = null;
      for (let arrKey in arr) {
        let temp = arr[arrKey];
        if (!temp.val) {
          continue;
        }
        res = {
          filterType: temp.type,
          filterValue: temp.val
        };
        break;
      }
      return res;
    };
    const refsPickTime = ref();
    const OpenTimeView = () => {
      refsPickTime.value.OpenPickTimeView();
    };
    const ChangeTime = msg => {
      let start = msg.start.arr.join('-');
      let end = msg.end.arr.join('-');
      FilterOptions.value[3].val = start + ',' + end;
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: _unref(show),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(show) ? show.value = $event : show = $event),
        position: "center",
        style: {
          height: '80%',
          width: '100%'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_form, {
          onSubmit: onSubmit
        }, {
          default: _withCtx(() => [_createVNode(_component_van_cell_group, {
            inset: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FilterOptions), (item, index) => {
              return _openBlock(), _createElementBlock("div", {
                key: index
              }, [item.field !== 'time' ? (_openBlock(), _createBlock(_component_van_field, {
                key: 0,
                label: item.name,
                modelValue: _unref(FilterOptions)[index].val,
                "onUpdate:modelValue": $event => _unref(FilterOptions)[index].val = $event
              }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_van_field, {
                label: item.name,
                modelValue: _unref(FilterOptions)[index].val,
                "onUpdate:modelValue": $event => _unref(FilterOptions)[index].val = $event,
                "is-link": "",
                readonly: "",
                onClick: OpenTimeView
              }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])]))]);
            }), 128))]),
            _: 1
          }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
            block: "",
            type: "primary",
            "native-type": "submit"
          }, {
            default: _withCtx(() => [_createTextVNode(" 提交 ")]),
            _: 1
          })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_button, {
            block: "",
            type: "warning",
            onClick: _cache[0] || (_cache[0] = $event => {
              _isRef(show) ? show.value = false : show = false;
            })
          }, {
            default: _withCtx(() => [_createTextVNode(" 取消 ")]),
            _: 1
          })])]),
          _: 1
        })]),
        _: 1
      }, 8, ["show"]), _createVNode(_unref(PickTime), {
        ref_key: "refsPickTime",
        ref: refsPickTime,
        onChangeTime: ChangeTime
      }, null, 512)]);
    };
  }
};