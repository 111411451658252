import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "TablePage"
};
import { ref, watch } from "vue";
export default {
  __name: 'TableNetWork-Page',
  props: ['Total', 'Size', 'Page'],
  emits: ['PageEvent'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const nowPage = ref(1); //当前页数

    const change = () => {
      emits('PageEvent', {
        page: nowPage.value
      });
    };
    watch(() => props.Page, NewVal => {
      console.log('watch', NewVal);
      nowPage.value = NewVal;
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_pagination = _resolveComponent("van-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_pagination, {
        modelValue: nowPage.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => nowPage.value = $event),
        "total-items": props.Total,
        "items-per-page": props.Size,
        onChange: change
      }, {
        "prev-text": _withCtx(() => [_createVNode(_component_van_icon, {
          name: "arrow-left"
        })]),
        "next-text": _withCtx(() => [_createVNode(_component_van_icon, {
          name: "arrow"
        })]),
        page: _withCtx(({
          text
        }) => [_createTextVNode(_toDisplayString(text), 1)]),
        _: 1
      }, 8, ["modelValue", "total-items", "items-per-page"])]);
    };
  }
};