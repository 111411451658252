import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "KeyListMenu"
};
import { ref, onMounted } from "vue";
import TableSetList from "@/components/Table/Table-SetList";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import KeyMenuSetKeyConfig from "@/views/Key/KeyList/Menu/KeyMenu-SetKeyConfig";
import Common_Router from "@/utils/Common/Common_Router";
export default {
  __name: 'KeyList-Menu',
  props: ['Config'],
  emits: ['MenuEvent'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;
    const actions = ref([]);
    onMounted(() => {
      if (props.Config.agent != 'self') {
        actions.value = [{
          name: '查看列表',
          type: 1
        }, {
          name: '清空列表',
          type: 2
        }, {
          name: '筛选卡密',
          type: 8
        }, {
          name: '迁回卡密',
          type: 9
        }];
      } else {
        actions.value = [{
          name: '查看列表',
          type: 1
        }, {
          name: '清空列表',
          type: 2
        }, {
          name: '模式切换',
          type: 3
        }, {
          name: '生成卡密',
          type: 4
        }, {
          name: '迁移卡密',
          type: 5
        }, {
          name: '回收卡密',
          type: 6
        }, {
          name: '卡密日志',
          type: 7
        }, {
          name: '筛选卡密',
          type: 8
        }];
      }
    });
    const show = ref(false);
    expose({
      show
    });
    const CacheField = 'KeyList';
    const refsTableSetList = ref();
    const refsKeyMenuSetKeyConfig = ref();
    const onSelect = item => {
      switch (item.type) {
        case 1:
          refsTableSetList.value.OpenView();
          break;
        case 2:
          CacheData.SaveData(CacheField, []);
          VantMsgLib.alertSuccess();
          break;
        case 3:
          refsKeyMenuSetKeyConfig.value.OpenSetKeyConfigView();
          break;
        case 4:
          Common_Router.push({
            name: 'Key.MakeKey'
          });
          break;
        case 5:
          Common_Router.push({
            name: 'Key.MoveKey',
            params: {
              Mode: 1
            }
          });
          break;
        case 6:
          Common_Router.push({
            name: 'Key.MoveKey',
            params: {
              Mode: 3
            }
          });
          break;
        case 7:
          Common_Router.push({
            name: 'Key.MakeKeyLog'
          });
          break;
        case 8:
          SendEvent(2);
          break;
        case 9:
          Common_Router.push({
            name: 'Key.MoveKey',
            params: {
              Mode: 2
            }
          });
          break;
      }
    };

    /**
     * 发送通知给父组件
     * @param type [类型]1.Config参数变动 2.打开筛选器
     * @param data
     * @param options
     * @constructor
     */
    const SendEvent = (type, data = null, options = null) => {
      emits('MenuEvent', {
        type,
        data,
        options
      });
    };
    return (_ctx, _cache) => {
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => show.value = $event),
        actions: actions.value,
        "close-on-click-action": "",
        onSelect: onSelect
      }, null, 8, ["show", "actions"]), _createVNode(_unref(TableSetList), {
        ref_key: "refsTableSetList",
        ref: refsTableSetList,
        field: CacheField
      }, null, 512), _createVNode(_unref(KeyMenuSetKeyConfig), {
        ref_key: "refsKeyMenuSetKeyConfig",
        ref: refsKeyMenuSetKeyConfig,
        onChangeKeyConfig: _cache[1] || (_cache[1] = msg => SendEvent(1, msg))
      }, null, 512)]);
    };
  }
};