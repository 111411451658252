import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import TableNetWork from "@/components/Table_NetWork/TableNetWork";
import { onBeforeMount, ref, toRaw, provide } from "vue";
import Apis_Key from "@/apis/Apis_Key";
import KeyListMenu from "@/views/Key/KeyList/KeyList-Menu";
import Common_Obj from "@/utils/Common/Common_Obj";
import KeyListFilter from "@/views/Key/KeyList/KeyList-Filter";
export default {
  __name: 'KeyList',
  props: ['UserName'],
  setup(__props) {
    const props = __props;
    provide('SearchIcon', true); //通知表格组件开启右上角放大镜图标(筛选功能)[Table-Header.Vue]
    /**
     * 接收表格组件传来的事件[单击表项,选中,...等]
     * @param options [对象]type:事件类型 data:数据 attach:附带数据/备注/..
     * @constructor
     */
    const TableNetWorkEvent = options => {
      console.log('TableEvent', options);
      // [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项 4.页数变动
      switch (options.type) {
        case 1:
          refsKeyListMenu.value.show = true;
          break;
        case 2:
          {
            let temp = Common_Obj.DeepClone(options.data);
            let tempArr = temp.map(item => {
              return {
                id: item.label,
                username: item.title
              };
            });
            CacheData.SaveData('KeyList', tempArr);
            VantMsgLib.alertSuccess();
            break;
          }
        case 4:
          pages.value.page = Number(options.data);
          GetKeyList();
          break;
        case 5:
          //点击了放大镜图标[筛选/搜索]
          refsKeyListFilter.value.OpenFilterView();
          break;
      }
    };
    const pages = ref({
      count: 0,
      page: 1,
      size: 20,
      talbeData: [],
      //{title,label,data}
      headerTitle: '卡密列表',
      checkbox: true //表格是否启用多选框
    });

    const Config = ref({
      type: 1,
      //卡密类型[type]: 1=注册码 2=充值码 3=全部
      status: 3,
      //卡密状态[status]: 0=未使用 1=已使用 3=全部
      agent: 'self' //查询账号,可以自己[self]或下级代理商
    });

    const GetKeyCount = () => {
      let config = toRaw(Config.value);
      Apis_Key.getAgentKeyCount(config.type, config.status, config.agent).then(res => {
        if (!Apis_Key.checkResCode(res)) {
          return;
        }
        let count = Number(res.count || 0);
        if (!count) {
          return;
        }
        pages.value.count = count;
        pages.value.page = 1;
        GetKeyList();
      });
    };
    const GetKeyList = () => {
      if (IsFilter.value) {
        pages.value.talbeData = GetFilterArr(pages.value.page, pages.value.size);
        return;
      }
      let config = toRaw(Config.value);
      Apis_Key.getAgentKeyList(config.type, config.status, config.agent, pages.value.size, pages.value.page).then(res => {
        if (!Apis_Key.checkResCode(res)) {
          return;
        }
        VantMsgLib.notify(2, '页码:' + pages.value.page + ' 数量:' + pages.value.count);
        pages.value.talbeData = [];
        let arr = res.data || [];
        for (let arrKey in arr) {
          let {
            key,
            id,
            money,
            use,
            time
          } = arr[arrKey];
          pages.value.talbeData.push({
            title: key,
            label: `${id}/${money}/${use}/${time}`
          });
        }
      });
    };
    onBeforeMount(() => {
      Config.value.agent = props.UserName;
      GetKeyCount();
    });
    const refsKeyListFilter = ref();
    const refsKeyListMenu = ref();
    const MenuEvent = ({
      type,
      data
    }) => {
      var _data$type, _data$status;
      console.log('MenuEvent', type, data);
      switch (type) {
        case 1:
          Config.value.type = (_data$type = data.type) !== null && _data$type !== void 0 ? _data$type : 1;
          Config.value.status = (_data$status = data.status) !== null && _data$status !== void 0 ? _data$status : 3;
          GetKeyCount();
          break;
        case 2:
          refsKeyListFilter.value.OpenFilterView();
          break;
      }
    };
    const FilterDataArr = ref([]);
    let IsFilter = ref(false);
    const FilterEvent = arr => {
      console.log('FilterEvent', arr);
      FilterDataArr.value = arr;
      IsFilter.value = true;
      pages.value.count = arr.length;
      pages.value.page = 1;
      GetKeyList();
    };
    const GetFilterArr = (page, limit) => {
      let max = page * limit;
      let min = max - limit;
      let arr = FilterDataArr.value.slice(min, max);
      let res = [];
      for (let arrKey in arr) {
        let {
          key,
          id,
          money,
          use,
          time
        } = arr[arrKey];
        res.push({
          title: key,
          label: `${id}/${money}/${use}/${time}`
        });
      }
      return res;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(TableNetWork), {
        Options: pages.value,
        onTableEvent: TableNetWorkEvent
      }, null, 8, ["Options"]), _createVNode(_unref(KeyListMenu), {
        ref_key: "refsKeyListMenu",
        ref: refsKeyListMenu,
        onMenuEvent: MenuEvent,
        Config: Config.value
      }, null, 8, ["Config"]), _createVNode(_unref(KeyListFilter), {
        ref_key: "refsKeyListFilter",
        ref: refsKeyListFilter,
        Config: Config.value,
        onFilterEvent: FilterEvent
      }, null, 8, ["Config"])], 64);
    };
  }
};